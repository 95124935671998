<template>
    <div style="background:white; height:100%">     
        <div style=" display:flex; width:100%; background:#fff;line-height:30px;height:38px;border-bottom:2px solid #389;padding-top:3px">   
            <div style="margin-right:10px;font-size:12px;margin-left:1px;">状态</div>
            <el-select v-model="stfgSet"   size="small" @change="qryTabData" style="height:30px;width:110px">
                <el-option label="所有" value="ALL"></el-option>
                <el-option label="未审核" value="N"></el-option>
                <el-option label="已审核" value="Y"></el-option>
            </el-select>
            <el-select v-model="selparam"  placeholder="请选择列名" clearable size="small" style="width:150px; ">
                <span v-for="item in tabArr" :key='item.key' >
                    <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                </span>
            </el-select>
            <el-input clearable ref="refinput" :placeholder="input_hold" v-model="txtparam" @keyup.enter.native="qryTabData" size="small" style="width:320px; height:30px; ">
                <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                    <span v-for="item in operArr" :key='item.IDSEQ'>
                        <el-option :label="item.CNAME" :value="item.SUBNO" ></el-option>
                    </span>
                </el-select>
                <el-button size="small" slot="append" icon="el-icon-search" @click="qryTabData" ></el-button>
            </el-input>
            <el-select v-model="selparam2"  placeholder="请选择列名" clearable size="small" style="width:150px;margin-left:20px; ">
                <span v-for="item in tabArr" :key='item.key'>
                    <el-option :label="item.title" :value="item.key" v-if="item.thide=='N'"></el-option>
                </span>
            </el-select>
            <el-input clearable :placeholder="input_hold" v-model="txtparam2" @keyup.enter.native="qryTabData" size="small" style="width:320px; height:30px; ">
                <el-select v-model="oper2" slot="prepend"   style="width:100px; " >
                    <span v-for="item in operArr" :key='item.IDSEQ'>
                        <el-option :label="item.CNAME" :value="item.SUBNO"  ></el-option>
                    </span>
                </el-select>
                <el-button size="small" slot="append" icon="el-icon-search" @click="qryTabData" ></el-button>
            </el-input>
 
        </div> 
        <el-table  :data="tabData" 
                border                            
                disabled-hover
                style="overflow:auto;margin-top:1px;"
                size="small"
                :height="tabHeight" 
                :row-key="getRowKey"  
                ref="tabref" 
                v-loading="loading"
                element-loading-text="数据加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(100, 100, 100, 0.8)"
            
                highlight-current-row>         
            <el-table-column  v-for="(item,index ) in tabArr.filter(item=>item.thide=='N')" :key="index"
                :prop="item.key"
                :label="item.title"
                show-overflow-tooltip
                :align="item.align"                                  
                :sortable="item.edcss!=='checkbox'&&item.key!=='RN'"
                :min-width="item.width">
                <template  slot-scope="scope" >
                    <!-- <el-link v-if="item.key=='IDNO'" :underline="false" @click="repShow(scope.row)"><div style="color:#409eff;border-bottom:1px solid red;width:100%;text-align:left;font-size:10px ">{{scope.row[item.key]}}</div> </el-link> -->
                    <el-checkbox disabled v-if="item.edcss=='checkbox'" true-label="Y" false-label="N" v-model="scope.row[item.key] "></el-checkbox>
                    <div v-else v-text="scope.row[item.key]" style="float:left"> </div>
                </template>
            </el-table-column>      
            <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="130">
                <template slot-scope="scope"> 
 
                    <span v-if="scope.row['STFG']=='N'"> 
                        <i class="iconfont icon-shenhe" style="color:#409EFF;font-size:12px;margin-right:3px;"></i>
                        <el-link :disabled="cfmBool"  @click="recSet(scope.row,'Y')" :underline="false"  ><span style="font-size:11px;margin-right:6px;">审核 </span></el-link>
                    </span>
                    <!-- <span v-if="scope.row['STFG']=='N'"> 
                        <i class="iconfont icon-shanchu1" style="color:#409EFF;font-size:12px;margin-right:3px;"></i>
                        <el-link   @click="recSet(scope.row,'X')" :underline="false"  ><span style="font-size:11px;margin-right:6px;">删除 </span></el-link>
                    </span> -->
                    <span v-if="scope.row['STFG']=='Y'"> 
                        <i class="iconfont icon-fanshenhe" style="color:#409EFF;font-size:12px;margin-right:3px;"></i>
                        <el-link  :disabled="cfmBool" @click="recSet(scope.row,'N')" :underline="false"  ><span style="font-size:11px;margin-right:6px;">弃审 </span></el-link>
                    </span>
                    <span v-if="scope.row['STFG']=='Y'"> 
                        <i class="iconfont icon-chakan1" style="color:#409EFF;font-size:12px;margin-right:3px;"></i>
                        <el-link  @click="editRec(scope.row,'Y',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:6px;">查看 </span></el-link>
                    </span>
                    <span v-if="scope.row['STFG']=='N'"> 
                        <i class="iconfont icon-bianji" style="color:#409EFF;font-size:12px;margin-right:3px; "></i>
                        <el-link :disabled="disabled" @click="editRec(scope.row,'N',scope.$index )" :underline="false"  ><span style="font-size:11px;margin-right:3px;">编辑 </span></el-link>
                    </span>
                </template>
            </el-table-column>                      
        </el-table>  
        <div style=" margin-top:5px;overflow: hidden;display:flex;background:white;font-size:12px ;padding-left:4px">
            <div style="float: left;font-size:12px">
                <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            </div>
            <div style="color:#606266;margin:7px 10px;font-size:12px;width:35px;">每页</div>

            <el-select v-model="rowSize" size="small" style="width:80px;"  @change="initPage">
                <el-option 
                    v-for="item in pageList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>

            <div style="color:#606266;margin:7px 10px;margin-left:80px;font-size:12px;width:30px;">跳至</div>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>

            <el-button @click="goNext" size="small" type="primary" style="width:50px;margin-left:15px;height:32px;">GO</el-button>
        </div> 
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="addWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">资料明细</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <div class="one-toolbar">
                   <span class="spanRow"> 基础信息</span>
                </div>        
                <Form :model="mainRow" :label-width="120" ref="supForm"  label-position="right" inline  >  
                    <span v-for="(item,index) in tabArr" :key="index" >
                        <FormItem   :class=" 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                            <template slot="label" ><span v-if="item.key=='SHPDTTM'" style="color:red;margin-right:8px">*</span>{{item.title}}</template>
                            <el-date-picker :disabled="saveFlag||item.disabled==='Y'?true:false" type="date"  v-if="item.edcss==='date'" v-model="mainRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-checkbox border disabled v-else-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="mainRow[item.key]" style="width:100%"></el-checkbox> 
                            <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false||saveFlag "  v-model="mainRow[item.key]"></el-input>
                        </FormItem>
                    </span>
                </Form>
 
            <div class="one-toolbar">
                <span class="spanRow"> 送货明细 </span>
            </div>
                <el-table  :data="dtlData" 
                    border                            
                    size="small"
                    ref="refdtl"
                    show-summary
                    :summary-method="getMatSum"
                    :height="300" 
                    :row-class-name="tableRowClass"
                    @row-click="dtlClick"
                    highlight-current-row >  
                    <el-table-column  v-for="(item,index ) in dtlCol.filter(item=>item.thide=='N')" :key="index"
                        :prop="item.key"
                        :label="item.title"
                        show-overflow-tooltip
                        :align="item.align"                                  
                        :sortable="item.edcss!=='checkbox'&&item.key!=='RN'"
                        :min-width="item.width">
                        <template  slot-scope="scope" >
                            <el-checkbox v-if="item.edcss=='checkbox'" true-label="Y" false-label="N" v-model="scope.row[item.key]" style="width:100%"> &nbsp;</el-checkbox> 
                            <span v-else  >{{scope.row[item.key]}}</span>
                        </template>
                    </el-table-column> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="80">
                        <template slot-scope="scope"> 
                            <i class="iconfont icon-bianji" style="color:#409EFF;font-size:12px;margin-right:3px;"></i>
                            <el-link :disabled="saveFlag"  @click="dtlEdit(scope.row,scope.$index)" :underline="false"  ><span style="font-size:11px;margin-right:2px;">编辑</span> </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="display:flex;flex-wrap:wrap;margin-top:10px;">
                    <div style="display:flex;flex-direction:column;width:300px;height:315px;border:1px solid #ccc;margin-right:10px">
                        <div style="line-height:30px;height:30px;background:#808695;padding-left:5px;color:white;display:flex">
                            产品照片(清晰产品外观+铭牌)
                            <!-- <Upload
                                ref="updir"
                                name="upfile"
                                :data="uploadData"
                                :show-upload-list="false"
                                :before-upload="beforeImgFile"
                                :on-success="successImgFile"
                                :on-progress="handleProgress"
                                accept="gif,png,jpg,jpeg,tiff,tif"                        
                                :format="['gif','png','jpg','jpeg','tiff','tif' ]"
                                :max-size="10240"
                                :on-format-error="imgFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :action="uploadUrl"
                                style="display: inline-block;width:80px; ">                           
                                <div style="font-size:10px ;margin-left:4px">
                                    <i class="iconfont icon-shangchuan"></i> <span class="oneMenu">上传图片 </span>
                                </div>                                                       
                            </Upload>  -->
                        </div>
                        <div v-if="FILEPATH" style=" height:260px ;width:298px; ">  
                            <!-- <div v-if="FILEPATH &&FILEPATH.lastIndexOf('.pdf')>-1" style="overflow-y:hidden">
                                <iframe :src="FILEPATH" frameborder="0" style="width: 100%; height: 100%"></iframe>
                            </div> -->
                            <el-image  fit="fill"  style="width:298px;height:260px;" :src="FILEPATH"> </el-image>
                            <div style="height:25px;background:#e8eaec;width:298px; display:flex;justify-content:space-between">
                                <el-link :underline="false" @click="imgDel( FILEPATH)"><Icon type="md-close" /> 删除</el-link>
                                <el-link :underline="false" @click="imgPreview( FILEPATH)"><Icon type="ios-search" /> 预览</el-link>
                            </div>
                        </div>
                    </div>
                    <div style="display:flex;flex-direction:column;width:300px;height:315px;border:1px solid #ccc;margin-right:10px">
                        <div style="line-height:30px;height:30px;background:#808695;padding-left:5px;color:white;display:flex">
                            外箱+托盘照片(清晰正唛+侧唛)
                            <!-- <Upload
                                name="upfile"
                                :data="uploadData"
                                :show-upload-list="false"
                                :before-upload="beforeImgFile"
                                :on-success="successImg2File"
                                :on-progress="handleProgress"
                                accept="gif,png,jpg,jpeg,tiff,tif"                        
                                :format="['gif','png','jpg','jpeg','tiff','tif' ]"
                                :max-size="10240"
                                :on-format-error="imgFormatError"
                                :on-exceeded-size="handleMaxSize"
                                :action="uploadUrl"
                                style="display: inline-block;width:80px; ">                           
                                <div style="font-size:10px ;margin-left:4px">
                                    <i class="iconfont icon-shangchuan"></i> <span class="oneMenu">上传图片 </span>
                                </div>                                                       
                            </Upload>  -->
                        </div>
                        <div v-if="FILEPATH2" style=" height:260px ;width:298px; ">  
                            <el-image   fit="fill"  style="width:298px;height:260px;" :src="FILEPATH2"> </el-image>
                            <div style="height:25px;background:#e8eaec;width:298px; display:flex;justify-content:space-between">
                                <el-link :underline="false" @click="imgDel2( FILEPATH)"><Icon type="md-close" /> 删除</el-link>
                                <el-link :underline="false" @click="imgPreview( FILEPATH2)"><Icon type="ios-search" /> 预览</el-link>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <span style="float:right">
                    <el-button type="info" @click="addWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                    <el-button @click="saveRec" :disabled="saveFlag" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 保存 </el-button> 
                </span>
            </div>
        </el-dialog>
        <!-- 提示框 -->
        <el-dialog v-dialogDrag :visible.sync="promptWin"  width="450px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">提示</span>
            </div>
            <span>{{prompt}}</span>
            <span slot="footer"  >
                <el-button type="primary" size="small" @click="promptWin = false">确 定</el-button>
            </span>
        </el-dialog>
 
        <!-- report preview -->
        <el-dialog class="pub_dialog" v-dialogDrag  :visible.sync="repWin" :width="tabWidth" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">报表预览</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <iframe :src="repUrl" frameborder="0" style="width: 100%; height: 100%"></iframe>
            </div>
        </el-dialog>
      
        <!-- 审批意见 -->
        <el-dialog v-dialogDrag :visible.sync="cfmWin" width="600px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">回复日期内容</span>
            </div> 
 
            <div style="font-size:11px">回复日期：</div>
            <el-date-picker type="date"   v-model="v_dttm" value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>
            <div style="font-size:11px">备注：</div>
            <el-input  type="textarea" :rows="7"  v-model="v_rmk"></el-input>
            <span slot="footer"  >
                <el-button type="info" @click="cfmWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="cfmSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
            </span>
        </el-dialog>
        <!-- 明细资料框 -->
        <el-dialog v-dialogDrag  :visible.sync="dtlWin"  width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;"> 编辑记录 </span>
            </div>
            <Form :model="dtlRow" :label-width="120"  label-position="right" inline  >
                <span v-for="(gpitem,index) in dtlArr" :key="index" style="margin-bottom:15px;">
                    <div class="one-toolbar">
                        <span class="spanRow">{{gpitem.gpnm}} </span> 
                    </div>  
                    <span v-for="(item,index) in gpitem.gplist" :key="index" >
                        <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.key"  v-if="item.FHIDE==='N'">                  
                            <el-date-picker :disabled=" saveFlag||item.DISABLED==='Y'?true:false" type="date"  v-if="item.EDCSS==='date'" v-model="dtlRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>    
                            <el-input-number controls-position="right" :precision="item.DOTNUM" :disabled=" saveFlag||item.DISABLED==='Y'?true:false"  v-else-if="item.EDCSS==='number'" v-model="dtlRow[item.key]" style="width:100%" ></el-input-number>   
                            <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="dtlRow[item.key]"></el-input>
                        </FormItem>
                    </span>
                </span>
            </Form> 
            <span slot="footer"  >
                <el-button type="info" @click="dtlWin=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                <el-button @click="dtlSure" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 确定 </el-button> 
            </span>
        </el-dialog>
        <el-dialog :visible.sync="showViewer" width="70vh" append-to-body>
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">预览</span>
            </div>
            <el-image fit="scale-down"  style=" margin:5px auto;height:500px;width:100% " :src="listurl"></el-image>
        </el-dialog>
        <!-- 上传进度 -->
        <el-dialog :visible.sync="videoFlag" width="150px" append-to-body :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'30px'}">
                <span style="line-height:30px;height:30px;font-size:16px;padding-left:5px;">正在上传中...</span>
            </div>
            <el-progress   type="circle" :percentage="videoUploadPercent"  ></el-progress>  
        </el-dialog>
    </div>  
</template>
<script>
 
import { getTabColOrData,getBaseData } from '../../api/user';
import { getDate } from '../../api/Select' 
import Index from '@/components/Tinymce/index.vue';
//局部注册
export default {
    name:"po_paynotice",
    data () {
        return {
            cfmWin:false,
            v_dttm:'',
            v_rmk:'',
            stkData:[],
            listurl:'',
            selAll:'N',
            allSel:'N',
            allNext:'N',
            isSame:'N',
            v_stkqty:'',
            v_stkrmk:'',
            v_poqty:'',
            v_poprc:'',
            v_rate:'',
            nextData:[],
            FILEPATH:'',
            FILEPATH2:'',
            loading:false,
            v_selAll:'N',
            repWin:false,
            repUrl:'',
            uploadData:{},
            addWin:false,
            uploadUrl:this.$store.state.baseUrl +'oss/fileUpload?username='+this.$cookies.get('v_username')+'&password='+this.$cookies.get('v_password'),
            stkCol:[],
            perFlag:'create',
            dtlRow:{},
            sendVal:'PRD',
            saveFlag:false,
            empWin:false,
            promptWin:false,
            showViewer:false,
            prompt:'',
            v_index:'',
            tmplRmk:'',
            stfgCss:'',
            stfgColor:'',
            mainRow:{},
            input_hold:'请输入查询的值',
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', 
            dtlWin:false,
            active:'', //二级菜单选中标志
            stfg_:'ALL',
            menuBool:false,
            disabled:false, //新增权限制
            stfgSet:'ALL',
            idseq:'', //菜单主键
            lstseq:'',//菜单主键
            dtlArr:[],
            formArr:[],
            tabData:[], //表数据
            allData:[],
            tabArr:[], //表列
            dtlData:[], //明细数据
            dtlCol:[],
            operArr:[],
            oper:'like',
            oper2:'like',
            comboArr:{},
            txtparam:'',
            selparam:'PONO',
            txtparam2:'',
            selparam2:'',
            txtparam3:'',
            selparam3:'',
            txtparam4:'',
            selparam4:'',
            oper4:'like',
            delArr:[],
    
            rowIndex:'-1',
            v_url:this.$store.state.baseUrl+'sysprivs/getManyRecord', //api请求路径
 
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
 
            v_usernm:this.$cookies.get('v_usernm'), //api 请求用户
            dataCount:0,
            rowSize:100,
            goPage:'',
            notNull:[],
            current:1,
            videoUploadPercent:0,
            videoFlag:false,
            stfgBool:true,
            addBool:false, //新增,为true时--禁止
            editBool:false, //编辑,为true时--禁止
            delBool:false, //删除 ,为true时--禁止
            cfmBool:false, //审核 ,为true时--禁止
            cancelBool:false, //作废 ,为true时--禁止
            v_rowIndex:'',
            stfgNo :'',
            stfgNm:'',
            v_row:{},
 
            v_tabrow:{},
            pageList: [ {
                value: 100,
                label: '100'
                }, {
                value: 200,
                label: '200'
            }],
            maxHeight:'',
        }
    },
    mounted () { 
        this.maxHeight = document.documentElement.clientHeight - 170
        //用户表单新增、编辑、审核、作废权限 :frmid:  ID
        getBaseData(this.$store.state.nologinUrl, 'idseq',this.$cookies.get('idseq'),'frmidseq','34','v_sys_user_priv','').then(res=>{
            this.privArr=res.data.result
            if (this.privArr[0].ISCFM==='N'){ //审核
                this.cfmBool=true
            }if (this.privArr[0].ISADD==='N'||this.privArr[0].DEPTID==='19541'){ //新增 ,部门为供应商时不能新增
                this.addBool=true
            }if (this.privArr[0].ISUPD==='N'){ //编辑
                this.editBool=true
            }if (this.privArr[0].ISCANCEL==='N'){ //作废
                this.cancelBool=true
            }
        })   
        //操作符
        getBaseData(this.$store.state.nologinUrl,'PARENTNO','41113','','','v_sys_base','').then(res=>{
            this.operArr =res.data.result
        })
 
 
        //表单所以下拦框动态赋值
        this.comSelect()
 
 
        //动态生成表单验证规则
 
        this.$nextTick(()=>{
            this.$refs['refinput'].focus()
        })
    },
    computed: {
        editHeight: function() {
            return (window.innerHeight - 430) + 'px';
        }, 
        tabHeight: function() {
            return (window.innerHeight - 160) + 'px';
        }, 
        tabWidth: function() {
            return (window.innerWidth - 140) + 'px';
        }, 
    },
    components: {
 
    },
    created () {       
        if (this.v_username){ 
            //查询数据
            this.getOrdList('')
            this.getTabField() //表列字段
    
        }else{
            let routeUrl = this.$router.resolve({
                path: '/login',
                query: {},
            });
            window.open(routeUrl.href, '_self');
        }
    },
    watch: {
 
    },
    methods: {
        tableRowClass({row,rowIndex}){
            row.index =rowIndex
        },
        successImgFile(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.FILEPATH =res.result
            this.tabData.splice(this.v_index,1,Object.assign(this.v_tabrow,{FILEPATH:res.result} ))
        },
        successImg2File(res,file){
            this.videoFlag = false
            this.videoUploadPercent=0
            this.FILEPATH2 =res.result
            this.tabData.splice(this.v_index,1,Object.assign(this.v_tabrow,{FILEPATH2:res.result} ))
        },
        //上传显示进度
        handleProgress(event,file,fileList){
            this.videoFlag = true
            this.videoUploadPercent = Math.floor(event.percent)
        },
        //文件超出指定文件大小限制的钩子
        handleMaxSize (file) {
            this.prompt =file.name + ' 文件太大, 不能超出10M'
            this.promptWin=true
        },
        imgFormatError (file) {
            this.prompt='文件格式错误,'+file.name + ' 不正确,请选择图片格式' 
            this.promptWin=true
        },
        //上传文件之前的钩子
        beforeImgFile (res, file) {     
            this.uploadData = {
                p_table:'',
                p_user:this.v_username
            }
            let promise = new Promise(resolve => {
            this.$nextTick(function() {
                    resolve(true);
                });
            });
            return promise;  
        },
        imgDel(path){
            this.$confirm('你确定要删除此图片吗','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox', 
                showClose:false,
                closeOnClickModal:false,
                closeOnPressEscape:false,
                type:'info',
            }).then(()=>{
                this.FILEPATH=''
                this.tabData.splice(this.v_index,1,Object.assign(this.v_tabrow,{FILEPATH:''} ))
            }).catch(()=>{})
        },
        imgDel2(path){
            this.$confirm('你确定要删除此图片吗','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox', 
                showClose:false,
                closeOnClickModal:false,
                closeOnPressEscape:false,
                type:'info',
            }).then(()=>{
                this.FILEPATH2=''
                this.tabData.splice(this.v_index,1,Object.assign(this.v_tabrow,{FILEPATH2:''} ))
            }).catch(()=>{})
        },
        //图片预览
        imgPreview(path){
            this.showViewer=true
            this.listurl=path
        },
        dtlSure(){
            if (!this.dtlRow['SHPQTY']){
                this.promptWin=true
                this.prompt='送货数量不能为空'
            }else{
                this.dtlWin =false
                //if (this.perFlag==='edit' ){ //编辑时要替换数组记录
                    this.dtlData.splice(this.rowIndex,1,this.dtlRow)
                // }else{
                //     this.dtlData.push(this.dtlRow)
                // }
            }
        },
        dtlEdit(row,index){
            this.rowIndex =index
            this.dtlRow =JSON.parse(JSON.stringify(row))
            this.dtlWin =true
        },
        //保存记录
        saveRec(){
            var vm =this 
            //发起保存请求   
            if (!this.mainRow['SHPDTTM']) {    
                this.prompt='送货日期不能为空'
                this.promptWin =true
            }else {
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'sysprivs/formOneSave?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_json:JSON.stringify(vm.mainRow), p_table:'po_sent',p_frmid:''},
                }).then(res=>{      
                    if(res.data.result ==='OK')  {  
                        console.log('idseq:'+res.data.idseq)
                        //明细保存：批量保存  res.data.idseq
                         this.$axios({   
                            method: 'post',
                            url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                            data: {p_json:JSON.stringify(this.dtlData), p_table:'po_sentdtl',p_idseq:res.data.idseq,p_action:'S' },
                         }).then(res=>{
                             if (res.data.code!=='200'){
                                 this.prompt =res.data.result
                                 this.promptWin =true
                             }
                         })
                        vm.addWin =false   
                        vm.getOrdList('')
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.result
                    }        
                }) 
            }
 
        },
        //状态审核
        recSet(row,val){
            let stfg_ =val=='Y'?'审核':val=='N'?'弃审':'删除'
            let v_dttm =val=='Y'? getDate('full') :''
            this.$confirm('你确定要'+stfg_+'此行记录吗','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox', 
                showClose:false,
                closeOnClickModal:false,
                closeOnPressEscape:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                let v_cond =' set stfg=\''+val+'\',cfmdttm=\''+v_dttm+'\''
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username +'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'po_sent',p_cond: v_cond},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        vm.qryTabData()     
                    }else{
                        this.promptWin=true
                        this.prompt=res.data.message
                    }        
                }) 
                 
            }).catch(()=>{})
        },
        //下推生成送货通知
        genSend(){
            let v_arr =[]
            this.nextData.forEach(item=>{
                if (item['SEL'] =='Y'){
                    v_arr.push(item)
                }
            })
            if (v_arr.length ==0){
                this.promptWin =true
                this.prompt ='请勾选要下推的记录'
            }else{
                this.$confirm('你确定下推勾选记录生成送货通知单吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false,
                    type:'info',
                }).then(()=>{
                })
            }
        },
        chkChg(row){
            if (!row['SENDQTY'] && row['SEL']=='Y'){
                row['SENDQTY'] =row['POQTY']
            }
        },
 
        cfmSure(){
            if (this.v_dttm ) {
                let vm =this
                this.$confirm('要确认此条记录吗','提示',{
                    confirmButtonText: '确定',
                    cancelButtonText:'取消',
                    customClass:'messageBox',
                    showClose:false,
                    closeOnClickModal:false,
                    closeOnPressEscape:false,
                    type:'info',
                }).then(()=>{
                    vm.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'mssql/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                        data: {p_key:'FinterID',p_value:vm.v_row['POSEQ'], p_table:'POOrder',p_cond:' set Cfmdttm=\''+getDate('full')+'\',Replydttm=\''+this.v_dttm+'\',frmk=\''+this.v_rmk+'\''},
                    }).then(res=>{ 
   
                        if (res.data.code=='200'){
                            vm.cfmWin =false
                            vm.tabData.splice(vm.v_rowIndex,1,Object.assign(vm.v_row,{'CFMDTTM':getDate('full'),'REPLYDTTM':vm.v_dttm,'RMK':vm.v_rmk}))
                        }else{
                            vm.promptWin =true
                            vm.prompt =res.data.result
                        }
                    })
                }).catch(()=>{})
            }else{
                this.promptWin =true
                this.prompt ='回复日期不能为空'
            }
        },
        getMatSum(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===1){
                    sums[index]='合计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if( column.property==='QTY'||column.property==='SHPQTY'  ){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(2) 
                        }else{
                            return prev
                        }
                    },'')
                }
            })
            return sums
        },
        getDtlKey(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
 
        repShow(row){
            this.repWin =true
            this.repUrl ='http://139.9.191.205:9000/FR/ReportServer?reportlet=/Reports/porep.cpt'+'&p_pono='+row['IDNO']
        },
 
  
        //动态赋值下拉框
        comSelect(){  
            this.$axios({
                method:'post', 
                url: this.$store.state.baseUrl+'noauth/getBaseData' ,
                data:{p_table:'v_sys_form_fld',p_key:'idseq',p_value:'34',p_key2:'fldty',p_value2:'HEAD',p_key3:'edcss',p_value3:'select'},
            }).then(res =>{
                if (res.data.result.length>0){
                    for(let k=0;k<res.data.result.length;k++){
                        getBaseData(this.$store.state.baseUrl+'noauth/getBaseData','parentno',res.data.result[k].COMBO ,'','','v_sys_base','').then(res2=>{
                            this.comboArr[res.data.result[k].FLDNO] =res2.data.result
                        })
                    }
                }
            })  
        },
 
        //订单明细
        getOrdList(cond){
            //获取所有产品记录且初始化分页记录       
            this.loading =true  
            this.$axios({   
                method: 'post',
                url:this.v_url+'?username='+this.v_username+'&password='+this.v_password,
                data: { p_cond:cond,p_table:'V_po_sent'},
            }).then(res=>{ 
                if (res.data.code==='200'){
                    this.allData =res.data.result
                    this.initPage() 
                    this.loading =false
                    this.$nextTick(() => { //有固定列时 查询后重新布局
                        this.$refs.tabref.doLayout();
                    })
                }else{
                    this.promptWin=true
                    this.prompt=res.data.result
                    this.loading =false
                }
                
            }) 
        },
        dtlClick(row,column){
            this.v_index =row.index 
            this.v_tabrow=row 
            this.FILEPATH =row['FILEPATH']
            this.FILEPATH2 =row['FILEPATH2']
        },
        //查询明细数据
        getDtlData(idseq){
            this.dtlData=[]
            this.$axios({   
                method: 'post',
                url:this.v_url+'?username='+this.v_username+'&password='+this.v_password,
                data: { p_key:'idseq',p_value:idseq,p_table:'V_po_sentdtl'},
            }).then(res=>{ 
                this.dtlData =res.data.result
                this.$nextTick(()=>{
                    if (res.data.result && res.data.result.length>0){
                        this.$refs['refdtl'].setCurrentRow(res.data.result[0])
                        this.FILEPATH =res.data.result[0]['FILEPATH']
                        this.FILEPATH2 =res.data.result[0]['FILEPATH2']
                        this.v_index =res.data.result[0]['index']
                        this.v_tabrow =res.data.result[0]
                    }
                    this.$refs['refdtl'].doLayout()
                })                
            })          
        },
        getNextData(idseq){
            this.nextData=[]
            this.$axios({   
                method: 'post',
                url:this.$store.state.baseUrl+'mssql/getBaseData?username='+this.v_username+'&password='+this.v_password,
                data: { p_key:'poseq',p_value:idseq,p_table:'V_LL_POdtl'},
            }).then(res=>{ 
                this.nextData =res.data.result           
            })          
        },
        //跳至页面
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.promptWin=true
                this.prompt='请输入跳至页数'
            }
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allData.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allData.length < this.rowSize){
                this.tabData = this.allData          
            }else{
                this.tabData =this.allData.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.tabData = this.allData.slice(_start,_end);
        },
        selRec(){
           
            this.nextData.forEach((item,index)=>{
                let v_qty =this.v_selAll =='Y'?item['POQTY']:''
                this.nextData.splice(index,1,JSON.parse(JSON.stringify(Object.assign(item,{SEL:this.v_selAll,SENDQTY:v_qty})) ))
            })
            
        },
        editRec(row,val,index){
            this.v_rowIndex =index
            this.saveFlag= val=='Y'?true:false
            this.mainRow =JSON.parse(JSON.stringify(row))
            this.addWin=true  
            this.getDtlData(row.IDSEQ) 
        },
        getRowKey(row){
            return row.IDSEQ
        },
        //查询
        qryTabData(){
            let cond_=''
            if (this.selparam){ //列1
                if (this.oper==='like'){
                    cond_=cond_+' and upper('+this.selparam+') like upper(\'%' + this.txtparam+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam+')' +this.oper+'upper(\''+this.txtparam+'\')'
                }
            }
            if (this.selparam2){ //列1
                if (this.oper2==='like'){
                    cond_=cond_+' and upper('+this.selparam2+') like upper(\'%' + this.txtparam2+'%\')'
                }else{
                    cond_=cond_+' and upper('+this.selparam2+')' +this.oper2+'upper(\''+this.txtparam2+'\')'
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and  stfg=\''+this.stfgSet+'\''
            }
            this.getOrdList( cond_)
 
        },
  
        //获取表列字段
        getTabField(){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','34','fldty','HEAD','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.result.length; k++){
                    vm.tabArr.push({ //向数组的开头 添加序列号
                        title: res.data.result[k].title,
                        key: res.data.result[k].key,
                        align: res.data.result[k].align,
                        width: res.data.result[k].width,
                        thide:res.data.result[k].THIDE,
                        fhide:res.data.result[k].FHIDE,
                        edcss:res.data.result[k].EDCSS,
                        disabled:res.data.result[k].DISABLED,
                        dotnum:res.data.result[k].DOTNUM,
                    })
                }
            })
            getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','34','gpnm,gpsort','','FLDTY','HEAD2',' order by gpsort').then((res) => {
                this.dtlArr= res.data.result             
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','34','FLDTY','HEAD2','','',' order by sortby,lstseq').then((res) => {
                for (let k=0; k<res.data.result.length; k++){
                    vm.dtlCol.push({ //向数组的开头 添加序列号
                        title: res.data.result[k].title,
                        key: res.data.result[k].key,
                        align: res.data.result[k].align,
                        width: res.data.result[k].width,
                        thide:res.data.result[k].THIDE,
                        fhide:res.data.result[k].FHIDE,
                        edcss:res.data.result[k].EDCSS,
                        disabled:res.data.result[k].DISABLED,
                        dotnum:res.data.result[k].DOTNUM,
                    })
                }
            })
 
        }
    }
}
</script>
<style scoped lang="less">
    .el-aside {
        height: calc(100vh - 100px);// vh就是当前屏幕可见高度的1%   只用设置左侧 aside 高度
    }
    .oneMenu{
 
        text-align:left;
        font-size:10px;
        color:white;
 
    }
    .oneMenu:hover{
        cursor: pointer;
        color:#a322d6;
    }
    .twoMenu{
        display:flex;
        flex-direction:column;
        line-height:40px;
        background:white;
        padding-left:35px;
 
    }
    .twoMenu:hover{
        cursor: pointer;
        color:#409EFF;
    }
    .top{
        padding: 10px;
        background: rgba(0, 153, 229);
        color: #fff;
        text-align: center;
        font-size:10px;
        border-radius: 2px;
    }  
    .el-header  {
        background-color: #fff;
        color: #409EFF;
        text-align: center;
        line-height: 60px;
        width:100%;
    }
  .el-main {
    background-color: #fff;
    color: rgb(207, 206, 206);
    text-align: center;
    height:100%;
  }
 .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 1px auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
    .item-width  {
        width: 32%;
        color:#409EFF;
        min-width:200px;
    }
    .active { 
        color:#D200D2; 
    } 
    .wraper{
        width:1250px;
        margin:1px auto;
    }
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px !important;
            color:rgb(128,128, 128);
            font-size:12px;
        }
        td {
            padding: 5px ;
            font-size:11px;
        }   
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
 
    .el-drawer__header{
        padding: 0px;
    }
 
    .messageBox .el-message-box__content{
        border-top: 1px solid #EBEEF5;
        height: 50px;
    }
    .el-message-box__message p{
        line-height: 30px;
    }
    .messageBox .el-message-box__btns{
        padding: 20px 15px 0;
    }
    .messageBox .el-message-box__header{
        background:#409eff;
    }
    .messageBox .el-message-box__title{
        color:white ;
        font-size:14px;
    }
</style>
 